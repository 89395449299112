import React, { useState, useEffect } from 'react';
import './form.css';  // Ensure the CSS path is correct

import {
    MDBInput,
    MDBCol,
    MDBRow,
    MDBCheckbox,
    MDBBtn,
    MDBIcon
} from 'mdb-react-ui-kit';

const Form = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [subscribe, setSubscribe] = useState(true);
    const [todayDate, setTodayDate] = useState(new Date().toLocaleDateString('en-CA'));


    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     // Basic validation (example)
    //     if (!email || !firstName || !contactNumber) {
    //         alert('Please fill in all required fields.');
    //         return;
    //     }

    //     const payload = {
    //         "userName": "atif@landtrack.pk",
    //         "fingerPrint": "UPWdLuPQYsjigBtZXMzAqIc2WHsWuu5aRbTy0SglSNM48phtIkHxNKfLxdHDB3fnxJQFr4jFpNsc6h4qj8NJnHMH5IBWLckZsiGz",
    //         "sendWelcomeLetter": 'true',
    //         "title": firstName + '' + lastName,
    //         "mobile": contactNumber,
    //         "personalMobileCountryCode": "+92",
    //         "whatsappCountryCode": "+92",
    //         "whatsapp": contactNumber,
    //         "tags": "string",
    //         "linkedIn": "string",
    //         "dealSize": "string",
    //         "platformID": "462",
    //         "statusID": "New Lead",
    //         "date": "string",
    //         "message": "string",
    //         "users": "atif@landtrack.pk",
    //         "companyName": "string",
    //         "position": "string",
    //         "email": email
    //     };

    //     try {
    //         const response = await fetch('https://app.fortify.biz/api/fortifyv2/SetLead', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(payload)
    //         });

    //         // if (!response.ok) {
    //         //     const errorData = await response.json();
    //         //     throw new Error(errorData.error || 'Failed to submit form');
    //         // }

    //         const result = await response.json();
    //         console.log(result);
    //         alert('Form submitted successfully!');
    //         setFirstName('');
    //         setLastName('');
    //         setEmail('');
    //         setContactNumber('');
    //         setSubscribe(true);

    //         console.log(payload);

    //     } catch (error) {
    //         console.error('Submission error:', error);
    //         alert(error.message);

    //         console.log(payload);
    //     }
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!email || !lastName || !firstName || !contactNumber) {
            alert('Please fill in all required fields.');
            // toast('Please fill in all required fields.', {
            // });
            return;
        };

        // ReactGA.event({
        //     category: 'Form',
        //     action: 'Submit',
        //     label: 'Form Submission'
        // });

        const payload = {
            "firstName": firstName,
            "lastName": lastName,
            "userName": "azam@landtrack.pk",
            "fingerPrint": "XBWxNl4sKfHLpl0wiIdZFJ9WMze2utIQsyAyl8RM4JtvyZYoVo3T9ihy97jybhOWZkQljg4J5tHgneJtbAvnjj9rY3RNWijjIIId",
            "userID": 1077,
            "personalMobile": contactNumber,
            "personalMobileCountryCode": "+92",
            // "rmIDs": "1077",
            "tag": "New Lead",
            "sendWelcomeLetter": false,
            "contactTypeIDs": "354",
            "leadStatus": [
                {
                    "message": "",
                    "contactusStatusID": 367,
                    "assignedTo": 1077,
                    "assignedToBy": 1077,
                    "date": todayDate
                }
            ]
        };


        try {
            const response = await fetch('https://core.fortify.biz/api/Contact/SetContact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const result = await response.json();
            console.log(result);
            alert('Form submitted successfully!');

            console.log(payload);
            setFirstName('');
            setLastName('');
            setEmail('');
            setContactNumber('');

        } catch (error) {
            console.error('Submission error:', error);
            alert(error.message);

            console.log(payload);
        }
    };


    return (
        <div id="form" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
            <form onSubmit={handleSubmit}>
                <MDBRow className='mb-4'>
                    <MDBCol>
                        <MDBInput value={firstName} onChange={e => setFirstName(e.target.value)} id='form3Example1' label='First name' />
                    </MDBCol>
                    <MDBCol>
                        <MDBInput value={lastName} onChange={e => setLastName(e.target.value)} id='form3Example2' label='Last name' />
                    </MDBCol>
                </MDBRow>
                <MDBInput value={email} onChange={e => setEmail(e.target.value)} className='mb-4' type='email' id='form3Example3' label='Email address' />
                <MDBInput value={contactNumber} onChange={e => setContactNumber(e.target.value)} className='mb-4' type='number' id='form3Example4' label='Contact No.' />
                <MDBCheckbox
                    wrapperClass='d-flex justify-content-center mb-4'
                    id='form3Example5'
                    label='Subscribe to our newsletter'
                    checked={subscribe}
                    onChange={e => setSubscribe(e.target.checked)}
                    defaultChecked
                />
                <MDBBtn type='submit' className='mb-4 submit_btn' block>Submit</MDBBtn>
            </form>
        </div>
    );
};

export default Form;
