import React from 'react';
import ReactDOM from 'react-dom/client';
import Hotjar from './Hotjar';
import './index.css';

import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import App from './App';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <Hotjar />
        <App />
    </>
);