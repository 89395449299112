import React, { useState, useEffect } from "react";

import Navigation from "./Components/Navigation/Navigation";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Services from "./Components/OurServices/Services";
import Video from "./Components/VideoSection/video";
import Mobile from "./Components/MobileSection/Mobile";
import Footer from "./Components/Footer/Footer";


const App = () => {
  return (
    <>
      <Navigation />
      <Home />
      <About />
      <Services />
      <Video />
      <Mobile />
      <Footer />

    </>
  );
};

export default App;

// #1076BC
// #6E6F72
// #2676BB
